import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pipe } from 'fp-ts/lib/pipeable';
import {
  makeStyles
} from '@material-ui/styles';
import { without, append, includes, keys, assocPath, reject, values, pickAll, assoc, take } from 'ramda';
import {
  Typography, Button,
  Dialog, DialogTitle, DialogContent, DialogActions, Badge,
  TextField, LinearProgress, useMediaQuery, Paper, IconButton
} from '@material-ui/core';
import {API, graphqlOperation} from 'aws-amplify';
// import ReactPhoneInput from 'react-phone-input-mui';
import {
  ArrowForward, Check, ArrowBack, Close
} from '@material-ui/icons';
import {
  update,
  initialState as ProjectState,
  submitForm
} from '../../state/project/ducks';
import theme from '../../themes/baseTheme';

import * as mutations from '../../graphql/mutations';


const useStyles = makeStyles((theme) => ({
  root: {
    backdropFilter: 'blur(5px)',
    WebkitBackdropFilter: 'blur(5px)',

  },
  progress: {
    padding: theme.spacing()
  },
  paperRoot: {
    border: `2px solid ${theme.palette.text.primary}`,
    background: theme.palette.background.default
  },
  btnGroup: {
    display: 'flex',
    // gap: `${theme.spacing(2)}px`,
    justifyContent: 'center',
    padding: theme.spacing(),
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexGrow: 1
    }
  },
  badgeCheck: {
    fontSize: 10
  },
  badge: {
    margin: theme.spacing()
  },
  unselected: {
    border: `2px solid ${theme.palette.text.disabled}`,
  },
  selected: {
    border: `2px solid ${theme.palette.text.primary}`,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing()
  },
  phoneInput: {
    width: '100%',
    flexGrow: 1
  },
  questionReview: {
    marginBottom: theme.spacing()
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogTitleText: {
    fontWeight: 'bold'
  }
}));

const Question = ({
  questions,
  currentQuestion,
  review,
  updateButton,
  updateTextField,
  updatePhoneField,
  updateButtonMultiple,
  updateAddressField
  // answer,
}) => {
  const classes = useStyles();
  const answer = questions[currentQuestion]?.answer;

  return (
    <div>
      {questions[currentQuestion]?.multipleSelect && <Typography align='center' variant='caption'>Select all that apply</Typography>}
      <div className={classes.btnGroup}>
        {questions[currentQuestion]?.options?.map(option =>
          <Badge
            className={classes.badge}
            key={option.value}
            color='primary'
            badgeContent={<Check className={classes.badgeCheck} />}
            invisible={!(questions[currentQuestion]?.multipleSelect && includes(option, answer)) && questions[currentQuestion]?.answer?.label !== option.label}
          >
            <Button
              fullWidth
              onClick={(e) => questions[currentQuestion]?.multipleSelect ? updateButtonMultiple(option, answer, currentQuestion) : updateButton(option, currentQuestion)}
              className={(questions[currentQuestion]?.answer?.label === option.label || (questions[currentQuestion]?.multipleSelect && includes(option, answer))) ? classes.selected : classes.unselected}
              variant='outlined'>
              {option.label}
            </Button>
          </Badge>)}
        {!questions[currentQuestion]?.options && currentQuestion !== 'phone' && (questions[currentQuestion]?.inputPropType !== 'address') &&
          <TextField
            fullWidth
            autoFocus={review ? false : true}
            multiline={Boolean(questions[currentQuestion]?.multiline)}
            name={questions[currentQuestion]?.inputPropType}
            type={questions[currentQuestion]?.inputPropType}
            placeholder={questions[currentQuestion]?.label}
            value={questions[currentQuestion]?.answer?.value || ''}
            onChange={e => updateTextField(e, currentQuestion)} />}
        {!questions[currentQuestion]?.options && (questions[currentQuestion]?.inputPropType === 'address') &&
          <div>
            <TextField
              fullWidth
              autoFocus={review ? false : true}
              name='street-address'
              type='text'
              placeholder={'Street Address'}
              label={'Street Address'}
              value={questions[currentQuestion]?.address?.streetAddress}
              onChange={e => updateAddressField(e, currentQuestion, 'streetAddress')} />
              <TextField
                fullWidth
                autoFocus={false}
                name='city'
                type='text'
                placeholder={'City'}
                label={'City'}
                value={questions[currentQuestion]?.address?.city}
                onChange={e => updateAddressField(e, currentQuestion, 'city')} />
                <TextField
                  fullWidth
                  autoFocus={false}
                  name='state'
                  type='text'
                  placeholder={'State'}
                  label={'State'}
                  value={questions[currentQuestion]?.address?.state}
                  onChange={e => updateAddressField(e, currentQuestion, 'state')} />
                  <TextField
                    fullWidth
                    autoFocus={false}
                    name='zip'
                    type='text'
                    placeholder={'Zip Code'}
                    label={'Zip Code'}
                    value={questions[currentQuestion]?.address?.zip}
                    onChange={e => updateAddressField(e, currentQuestion, 'zip')} />
          </div>
        }
        {/*{!questions[currentQuestion]?.options && currentQuestion === 'phone' &&
          <ReactPhoneInput
            defaultCountry='us'
            disableCountryCode
            placeholder={questions[currentQuestion]?.label}
            value={questions[currentQuestion]?.answer?.value || ''}
            onChange={e => updatePhoneField(e, currentQuestion)}
            containerClass={classes.phoneInput}
            inputExtraProps={{
              name: 'phone',
              required: true,
              autoFocus: review ? false : true,
              fullWidth: true
            }}
            component={TextField} />}*/}
          {!questions[currentQuestion]?.options && currentQuestion === 'phone' &&
            <TextField
              fullWidth
              autoFocus={review ? false : true}
              multiline={Boolean(questions[currentQuestion]?.multiline)}
              name={questions[currentQuestion]?.inputPropType}
              type={questions[currentQuestion]?.inputPropType}
              placeholder={questions[currentQuestion]?.label}
              value={questions[currentQuestion]?.answer?.value || ''}
              onChange={e => updatePhoneField(e, currentQuestion)} />}
      </div>
      {questions[currentQuestion]?.answer?.label === 'Other' &&
        <TextField
          value={questions[currentQuestion]?.answer?.value || ''}
          fullWidth
          autoFocus
          placeholder='Other...'
          onChange={e => updateTextField(e, currentQuestion)} />}
    </div>
  )
}

const FormComponent = ({
  project,
  update,
  submitForm
}: {
  project: typeof ProjectState;
  update: any;
}) => {
  const classes = useStyles();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [state, setState] = React.useState({data: null});
  const reviewForm = () => {
    update(['form', 'review'], true);
  }
  const {
    form: {
      currentQuestion,
      questions: _questions,
      review
    }
  } = project;
  const questionKeys = pipe(_questions,
    q => values(q),
    qs => qs.filter(q => !q.skip),
    qs => qs.map(q => q.type)
  );
  const questions = pickAll(questionKeys, _questions);
  const generateTableRows = (fields) => {
    const rows = fields.reduce((acc, val, idx) => {
      const headRow = ((idx % 2) === 0) ? `<tr style="background:rgba(0,0,0,0.3);">` : "<tr>";
      const value = val.subFields ? val.subFields?.reduce((acc, v) => {
        if (!questions[v.key]?.answer?.value) return acc;
        return acc.concat(`<p>${questions?.[v.key]?.reportLabel || v.label || questions?.[v.key]?.label}: ${questions[v.key]?.answer?.value}</p>`);
      }, '') : `${val.value}`;
      return acc.concat(
        headRow
          + `<th style="max-width: 300px;text-align:left;">`
            + `${val.label}`
          + "</th>"
          + "<td>"
            + value
          + "</td>"
        + "</tr>"
      );
    }, '');
    const html =
    `<h1 style="font-family:Arial;">C&R Asphalt Free Estimate Form</h1>`
    + `<table style="font-family:Arial;border:2px solid rgb(237, 28, 36);">`
    + rows
    + `</table>`
    return html;
  }
  const fields = pipe(questions,
    q => keys(q),
    ks => reject(k => questions?.[k]?.noInput, ks),
    ks => reject(k => questions?.[k]?.inputPropType === 'file', ks),
    ks => reject(k => questions?.[k]?.answer === null, ks),
    ks => reject(k => questions?.[k]?.answer.length === 0, ks),
    ks => reject(k => questions?.[k]?.answer?.value === "", ks),
    ks => reject(k => questions?.[k]?.answer?.value === null, ks),
    // ks => reject(k => includes(k, hiddenFields), ks),
    ks => ks?.reduce((acc, k) => pipe(acc,
      a => assocPath([k], questions?.[k]?.answer?.value, a),
      a => {
        const _value = questions?.[k]?.answer?.value || questions?.[k]?.answer;
        const value = typeof _value === 'object' ? _value.reduce((acc, val, idx) => {
          if (idx === 0) {
            return acc.concat(val.value);
          }
          return acc.concat(`, ${val.value}`)
        }, '') : _value.replace(/\n/g, "<br />");
        return assocPath([k, 'value'], value, a)
      },
      a => assocPath([k, 'subFields'], questions?.[k]?.subFields, a),
      a => assocPath(
        [k, 'label'],
        questions?.[k]?.reportLabel ||
        questions?.[k]?.label
          || questions?.[k]?.questionText
          || questions?.[k]?.questionTextHeader
          || questions?.[k]?.questionTextSubHeader, a),
    ), {}),

  );
  const emailPreview = generateTableRows(values(fields));
  const setResidential = () => {
    update(['form', 'questions'], pipe(_questions,
      q => assocPath(['organizationName', 'skip'], true, q),
      q => assocPath(['organizationAddress', 'skip'], true, q),
    ))
  };
  const setFarm = () => {
    update(['form', 'questions'], pipe(_questions,
      q => assocPath(['organizationName', 'questionText'], `What is your farm's name?`, q),
      q => assocPath(['organizationAddress', 'questionText'], `What is the street address of your farm?`, q),
      q => assocPath(['organizationName', 'skip'], false, q),
      q => assocPath(['organizationAddress', 'skip'], false, q),
    ))
  };
  const setCommercial = () => {
    update(['form', 'questions'], pipe(_questions,
      q => assocPath(['organizationName', 'questionText'], `What is your company's name?`, q),
      q => assocPath(['organizationAddress', 'questionText'], `What is the street address of your company?`, q),
      q => assocPath(['organizationName', 'skip'], false, q),
      q => assocPath(['organizationAddress', 'skip'], false, q),
    ))
  };
  const setOther = () => {
    update(['form', 'questions'], pipe(_questions,
      q => assocPath(['organizationName', 'questionText'], `What is your company or organization's name?`, q),
      q => assocPath(['organizationAddress', 'questionText'], `What is the street address of your company or organization?`, q),
      q => assocPath(['organizationName', 'skip'], false, q),
      q => assocPath(['organizationAddress', 'skip'], false, q),
    ))
  };
  // const initAddress = {
  //   streetAddress: null,
  //   city: null,
  //   state: null,
  //   zip: null
  // };
  // const [address, setAddress] = React.useState(initAddress);
  React.useEffect(() => {
    if (
      _questions?.[currentQuestion]?.address?.streetAddress &&
      _questions?.[currentQuestion]?.address?.city &&
      _questions?.[currentQuestion]?.address?.state &&
      _questions?.[currentQuestion]?.address?.zip
    ) {
      const val = {
        target: {
          value: _questions?.[currentQuestion]?.address.streetAddress + '\n'
            + _questions?.[currentQuestion]?.address.city + ', ' + _questions?.[currentQuestion]?.address.state + ' ' + _questions?.[currentQuestion]?.address.zip
        }
      }
      updateTextField(val, currentQuestion);
    }
  }, [_questions?.[currentQuestion]?.address]);
  React.useEffect(() => {
    update(['form', 'report'], emailPreview);

  }, [_questions]);
  React.useEffect(() => {
      console.log('setting project type');
      _questions?.projectType?.answer?.value === 'Residential' && setResidential();
      _questions?.projectType?.answer?.value === 'Farm' && setFarm();
      _questions?.projectType?.answer?.value === 'Commercial' && setCommercial();
      _questions?.projectType?.answer?.label === 'Other' && setOther();
  }, [_questions.projectType]);

  const query = `
    query {
      listProjectForms {
       items {
         email
         firstName
         id
       }
     }
    }
  `;


  if (!currentQuestion) return null;
  const close = () => update(['form', 'currentQuestion'], null);
  const updateTextField = (e, currentQuestion) => {
    update(['form', 'questions', currentQuestion, 'answer', 'value'], e.target.value);
  };
  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const number = '(' + match[1] + ') ' + match[2] + '-' + match[3];
      return number;
    }
    return null;
  }
  const updatePhoneField = (e, currentQuestion) => {
    const phone = formatPhoneNumber(e.target.value);

    if (!phone) {
      e.target.value.length < 14 && (update(['form', 'submitFormError', 'msg'], 'Please enter a valid 10-digit phone number'));
      return update(['form', 'questions', currentQuestion, 'answer', 'value'], take(14, e.target.value));
    }
    update(['form', 'submitFormError'], null);
    update(['form', 'questions', currentQuestion, 'answer', 'value'], take(14, phone));
  };
  const answer = questions[currentQuestion]?.answer;
  const updateButton = (value, currentQuestion) => {
    return update(['form', 'questions', currentQuestion, 'answer'], value)
  }
  const updateButtonMultiple = (value, answer, currentQuestion) => {
    console.log('Updating multiple select', value, currentQuestion);

    return update(
      ['form', 'questions', currentQuestion, 'answer'],
      includes(value, answer) ? answer.filter(i => i !== value) : append(value, answer)
    )
  };
  const updateAddressField = (e, currentQuestion, addressField) => {
    return update(
      ['form', 'questions', currentQuestion, 'address', addressField],
      e.target.value
    )
  }
  const curQuestionIdx = Object.values(questions).indexOf(questions[currentQuestion]);
  const nextQuestionId = curQuestionIdx !== undefined && Object.values(questions)?.[curQuestionIdx + 1]?.type;
  const previousQuestionId = curQuestionIdx !== undefined && Object.values(questions)?.[curQuestionIdx - 1]?.type;
  const progress = ((curQuestionIdx) / (Object.keys(questions).length - 1)) * 100;
  const closeProject = () => update(['form', 'currentQuestion'], null);
  const nextButtonDisabled = (currentQuestion === "phone" && questions["phone"]?.answer?.value?.length < 14)
  || (questions[currentQuestion]?.required && !questions[currentQuestion]?.multipleSelect && !questions[currentQuestion]?.answer?.value)
  || questions[currentQuestion]?.answer?.length === 0;
  console.log((currentQuestion === "phone" && questions["phone"]?.answer?.value?.length < 14), questions["phone"]?.answer?.length < 14, currentQuestion);

  return <div><Dialog
    open={Boolean(currentQuestion)}
    onClose={close}
    fullScreen={screenIsSmall}
    className={classes.root}
    maxWidth="sm"
    fullWidth
    disableRestoreFocus
    PaperProps={{
      square: true,
      className: classes.paperRoot
    }}>
    <DialogTitle className={classes.dialogTitle}>
      <div className={classes.dialogTitle}>
        <Typography variant='body1' className={classes.dialogTitleText}>{!review ? questions[currentQuestion]?.questionText : 'Please confirm your information:'}</Typography>
        <IconButton onClick={closeProject}><Close /></IconButton>
      </div>

    </DialogTitle>
      <DialogContent>
      {/*<div style={{maxWidth: 600, margin: 'auto'}}>
        <div dangerouslySetInnerHTML={{__html: emailPreview}} />
      </div>*/}
        {review && <div>
          {keys(questions).map((q, i) => <div className={classes.questionReview} key={i}>
            <Typography color='textSecondary'>{questions[q]?.questionText}</Typography>
            <Question
              review={review}
              currentQuestion={q}
              questions={_questions}
              updateTextField={updateTextField}
              updateButton={updateButton}
              updateButtonMultiple={updateButtonMultiple}
              updatePhoneField={updatePhoneField}
              answer={questions[q]?.answer}
              updateAddressField={updateAddressField} />
          </div>)}
        </div>}
        {!review &&
          <Question
            review={review}
            currentQuestion={currentQuestion}
            questions={_questions}
            updateTextField={updateTextField}
            updateButton={updateButton}
            updatePhoneField={updatePhoneField}
            updateButtonMultiple={updateButtonMultiple}
            answer={answer}
            updateAddressField={updateAddressField} />}
            {project.form.submitFormError?.msg && <Paper className={classes.error}><Typography>{project.form.submitFormError?.msg}</Typography></Paper>}
      </DialogContent>

      <DialogActions>

        {previousQuestionId && !review && <Button
          disabled={!previousQuestionId}
          variant='outlined'
          onClick={() => previousQuestionId && update(['form', 'currentQuestion'], previousQuestionId)}>
          <ArrowBack />
            Previous
        </Button>}
        {
          nextQuestionId && <Button
            disabled={nextButtonDisabled}
            variant='contained'
            onClick={() => {
              nextQuestionId && update(['form', 'currentQuestion'], nextQuestionId);
            }}
            color='secondary'>
            Next
            <ArrowForward />
          </Button>
        }
        {
          !nextQuestionId && !review && <Button
            onClick={reviewForm}
            disabled={(questions[currentQuestion]?.required && !questions[currentQuestion]?.multipleSelect && !questions[currentQuestion]?.answer?.value) || questions[currentQuestion]?.answer?.length === 0}
            variant='contained'
            color='secondary'>
            Review
          {/*<ArrowForward />*/}
          </Button>
        }
        {
          review && <Button
            onClick={submitForm}
            disabled={project.form.submittingForm || (questions[currentQuestion]?.required && !questions[currentQuestion]?.multipleSelect && !questions[currentQuestion]?.answer?.value) || questions[currentQuestion]?.answer?.length === 0}
            variant='contained'
            color='secondary'>
            {project.form.submittingForm ? 'Submitting Form...' : `Submit`}
      </Button>
        }
      </DialogActions>
    <div className={classes.progress}>
      <LinearProgress color='secondary' variant='determinate' value={progress} />
      <Typography align='center' variant='caption'>{`${parseInt(progress)}% complete`}</Typography>
    </div>

  </Dialog>
  </div>
}

const mapStateToProps = (state: {
  project: typeof ProjectState
}) => {
  const { project } = state;
  return {
    project
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ...bindActionCreators({
      update,
      submitForm
    }, dispatch)
  };
};

const Form = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);

export default Form;
