import { createTheme as createMuiTheme } from "@material-ui/core/styles";
// import createTheme from "@mui/material/styles/createTheme"
import { pipe } from 'fp-ts/lib/pipeable';
import { assocPath } from 'ramda';
import { baseTheme } from './baseTheme';

const MuiTooltipTooltip = {
  backgroundColor: 'white',
  color: 'black',
  borderRadius: 0,
  boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
};
const MuiPaperElevation1 = {
  boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
};
const theme = createMuiTheme(pipe(baseTheme,
  th => assocPath(['palette', 'type'], 'light', th),
  th => assocPath(['overrides', 'MuiTooltip', 'tooltip'], MuiTooltipTooltip, th),
  th => assocPath(['overrides', 'MuiPaper', 'elevation1'], MuiPaperElevation1, th),
));

// export const themeV2 = createTheme({
//   palette: {
//     mode: 'light'
//   },
//   components: {
//     MuiPaper: {
//       styleOverrides: {
//         elevation1: MuiPaperElevation1
//       }
//     }
//   },
// });

export default theme;
